<template>
  <div @click.stop>
    <label v-if="label" class="form-label" @click="open">{{ name }}:</label>
    <div class="relative">
      <Multiselect
        :modelValue="modelValue"
        @update:modelValue="(value) => $emit('update:modelValue', value)"
        :name="name"
        :label="label"
        :placeholder="placeholder"
        :multiple="true"
        :options="optionsList"
        :loading="isSearching"
        track-by="id"
        select-label=""
        deselect-label=""
        :hide-selected="true"
        :hide-label="true"
        @search-change="search"
      >
        <slot />
      </Multiselect>
    </div>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import Http from '@/Utils/Http'
import _debounce from 'lodash/debounce'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },

  inheritAttrs: false,
  props: {
    modelValue: Object,
    url: String,
    trackBy: String,
    searchBy: [String, Array],
    options: [Array],
    name: String,
    label: String,
    placeholder: String,
    error: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      optionsList: this.options || [],
      isSearching: false,
    }
  },
  computed: {
    modelValueObjects() {
      if (modelValue.isArray()) {
        return modelValue.map((x) => {
          id: x
        })
      }
      return null
    },
  },
  watch: {
    url: _debounce(function () {
      this.search()
    }, 300),
  },
  mounted() {
    this.search()
  },
  methods: {
    search(query) {
      this.isSearching = true
      Http.get(this.url, { params: { search: query } })
        .then((response) => {
          this.optionsList = response.data.data
        })
        .finally(() => {
          this.isSearching = false
        })
    },
  },
}
</script>
