<template>
  <div class="w-full">
    <div class="divide-y divide-gray-900/10">
      <Disclosure as="div" class="items-center pt-6" v-slot="{ open }">
        <div class="flex items-center justify-start">
          <DisclosureButton class="flex items-center text-gray-900">
            <span class="mr-2 mt-1 flex h-6 items-center">
              <ChevronRightIcon
                :class="!open ? 'h-6 w-6 transition' : 'h-6 w-6 rotate-90 transition'"
                aria-hidden="true"
              />
            </span>
          </DisclosureButton>
          <div class="w-full">
            <front-text-input
              class="placeholder-shown:border-0 placeholder-shown:bg-transparent placeholder-shown:text-xl"
              :placeholder="$t('Untitled Page')"
              v-model="title"
            />
          </div>

          <Popover v-if="open" class="relative">
            <PopoverButton class="inline-flex items-center gap-x-1 text-sm text-gray-600">
              <EllipsisVerticalIcon class="h-8 w-8 text-gray-400" aria-hidden="true" />
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="opacity-0 translate-y-1"
              enter-to-class="opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-150"
              leave-from-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
            >
              <PopoverPanel class="max-w-min absolute left-1/2 z-30 mt-5 flex -translate-x-1/2">
                <div class="w-28 shrink rounded bg-white p-2 text-sm text-gray-600 shadow-lg ring-1 ring-gray-900/5">
                  <a @click="showMovePageModal = true" class="block cursor-pointer p-2 hover:bg-gray-100">Move page</a>
                  <a @click="handleDeletePage" class="block cursor-pointer p-2 hover:bg-gray-100">Delete page</a>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
        <DisclosurePanel as="dd">
          <div class="mt-3">
            <div class="rounded-t-lg">
              <div class="flex flex-row divide-x bg-gray-50 text-sm text-gray-500">
                <p class="basis-2/3 px-2 py-2 sm:px-4">Question</p>
                <p class="basis-1/3 px-2 py-2 sm:px-4">Type of response</p>
              </div>

              <slot />
            </div>
          </div>
          <div class="mt-5 max-w-lg">
            <button
              @click="addQuestion"
              type="button"
              class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <PlusIcon class="mr-2 h-4 w-4 text-qualify-red-500" aria-hidden="true" />
              Add question
            </button>
            <button
              @click="addSection"
              type="button"
              class="mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold leading-4 text-qualify-red-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <QueueListIcon class="mr-2 h-4 w-4 text-qualify-red-500" aria-hidden="true" />
              Add section
            </button>
          </div>

          <div class="mt-5 max-w-lg">
            <p class="cursor-pointer text-sm text-gray-500 underline" @click="addPage">Add page</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
    <front-form-page-move-modal :templateData="templateData" :show="showMovePageModal" @close="closeMovePageModal" />
  </div>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { CheckIcon, EllipsisVerticalIcon, QueueListIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Bars3Icon,
    ChevronRightIcon,
    CheckIcon,
    EllipsisVerticalIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    PlusIcon,
    Bars3Icon,
    QueueListIcon,
  },
  props: {
    page: Object,
    templateData: Array,
  },
  emits: ['add:page', 'add:question', 'add:section', 'change:pageTitle'],
  data() {
    return {
      title: this.page.page.title,
      showMovePageModal: false,
    }
  },
  watch: {
    title(newTitle) {
      const page = this.page.page.uuid
      this.$emit('change:pageTitle', { page, newTitle })
    },
  },
  methods: {
    addPage() {
      this.$emit('add:page', this.page)
    },
    addQuestion() {
      this.$emit('add:question', this.page)
    },
    addSection() {
      this.$emit('add:section', this.page)
    },
    removeQuestionFromPage() {
      this.questions.pop()
    },
    addQuestionToSection(section) {
      var obj = {}
      section.questions.push(obj)
    },
    removeQuestionFromSection(section) {
      section.questions.pop()
    },
    closeMovePageModal() {
      this.showMovePageModal = false
    },
    handleDeletePage() {
      console.log('page deleted')
    },
  },
}
</script>
