<template>
  <styled-modal max-width="sm" :show="show" @close="close">
    <template #title>{{ $t('Employer Signature Request') }}</template>

    <template #content>
      <div class="max-w-sm">
        <form @submit="submit">
          <div class="-mb-8 -mr-6 flex flex-col p-2">
            <div class="w-full pb-8 pr-6">
              <text-input type="email" v-model="form.email" :label="$t('Employer Email')" :error="form.errors.email" />
            </div>
            <div class="w-full pb-8 pr-6">
              <front-textarea-input v-model="form.message" :label="$t('Message')" :error="form.errors.message" />
            </div>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-wrap justify-end space-y-2 sm:space-x-4 sm:space-y-0">
        <button class="w-full px-6 py-2 text-gray-500 sm:w-auto" @click.prevent="close">{{ $t('Nevermind') }}</button>
        <front-loading-button :loading="form.processing" type="button" @click="submit">
          <span>{{ $t('Send') }}</span>
        </front-loading-button>
      </div>
    </template>
  </styled-modal>
</template>

<script>
import { useForm } from '@inertiajs/vue3'

export default {
  props: {
    show: Boolean,
    certificate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        email: null,
        message: null,
      }),
    }
  },
  methods: {
    close() {
      this.form.reset()
      this.form.clearErrors()
      this.$emit('close')
    },
    submit() {
      this.form.post(this.route('front.certificates.signature-request.store', this.certificate?.id), {
        onSuccess: () => this.close(),
      })
    },
  },
}
</script>
