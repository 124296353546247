<template>
  <div class="my-6">
    <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
    <span>{{ formAnswer.form_content.title }}</span>
  </div>
  <Multiselect
    v-if="isDropDown"
    :model-value="value"
    :disabled="!choices"
    :name="$t('Options')"
    :multiple="isMultiple"
    :options="choices"
    :placeholder="$t('Select...')"
    :searchable="false"
    label="name"
    track-by="id"
    @update:model-value="updateDropdownValue"
  >
    <template #noResult>{{ $t('No options found.') }}</template>
    <template #noOptions>{{ $t('No options found.') }}</template>
    <template #option="{ option }">
      <span>{{ option.name }}</span>
    </template>
    <template #tag="{ option, remove }">
      <span class="custom__tag">
        <option-tag :modelValue="option" />
      </span>
    </template>
    <template #singleLabel="{ option }">
      <option-tag :modelValue="option" />
    </template>
  </Multiselect>
  <div v-else>
    <div class="grid grid-cols-3 gap-2">
      <button
        v-for="choice in choices"
        class="px-4 py-4 border rounded-md w-full text-center hover:bg-slate-200 active:bg-slate-200"
        :class="{ 'text-slate-200 bg-darkGray-100': choice.is_selected }"
        :style="choice.is_selected ? `background-color: ${choice.color}` : ``"
        @click="toggleChoiceSelection(choice)"
        :aria-pressed="choice.is_selected ? 'true' : 'false'"
        :aria-label="choice.name"
      >
        <span>{{ choice.name }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import OptionTag from '@/Shared/Answerables/OptionTag.vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
    OptionTag,
  },
  props: {
    modelValue: Array,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
    }
  },
  computed: {
    isMultiple() {
      return this.formAnswer.form_content.form_questionable.is_multiple
    },
    isDropDown() {
      const select = this.formAnswer.form_content.form_questionable.select
      return select.scope_level === 'organization' || select.choices.length > 5
    },
    choices() {
      return this.formAnswer.form_content.form_questionable.select.choices.map((choice) => ({
        ...choice,
        is_selected: this.value.some((valueChoice) => valueChoice.id == choice.id),
      }))
    },
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
  methods: {
    toggleChoiceSelection(choice) {
      const index = this.value.findIndex((v) => v.id === choice.id)
      if (index === -1) {
        if (!this.isMultiple) {
          this.value = []
        }
        this.value.push(choice)
      } else {
        this.value.splice(index, 1)
      }
    },
    updateDropdownValue(value) {
      if (!Array.isArray(value)) {
        this.value = []
        return this.value.push(value)
      }
      return (this.value = value)
    },
  },
}
</script>
