export function logoName(color) {
  return `${color} ${location.host}`
}

export function isUrl(...urls) {
  return urls?.flat().filter((url) => {
    if (url.slice(-1) === '*') {
      return location.pathname.substr(1).startsWith(url.slice(0, -1))
    } else {
      return location.pathname.substr(1) === url.replace(/\/$/, '')
    }
  }).length
}

export function isNotUrl(prefix, ...urls) {
  return !isUrl(prefix, ...urls)
}

export function query(data) {
  return '?' + new URLSearchParams(data).toString()
}

export function isTenantWorksite() {
  return this.$page.props.app.tenant.id == 1
}
