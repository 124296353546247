<template>
  <div v-if="links.length > 3" class="mt-6 flex justify-end">
    <template v-for="(link, key) in links">
      <inertia-link
        v-if="link.url != null && Number.isInteger(link.label)"
        :key="key"
        class="mr-1 rounded border px-3 py-2 text-sm font-semibold hover:bg-ts-gray-700 hover:text-white"
        :class="link.active ? 'bg-ts-gray-700 text-white' : 'bg-white text-ts-gray-700'"
        :href="link.url"
        replace
        :preserveScroll="preserveScroll"
        :preserveState="preserveState"
      >
        {{ link.label }}
      </inertia-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
    highlights: Array,
    preserveScroll: {
      type: Boolean,
      default: false,
    },
    preserveState: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
