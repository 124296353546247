<template>
  <front-checkbox-input
    class="my-6"
    v-model="value"
    :required="formAnswer.form_content.is_required"
    :label="formAnswer.form_content.title"
  />
</template>
<script>
import FrontCheckboxInput from '../FrontCheckboxInput.vue'

export default {
  components: {
    FrontCheckboxInput,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
