<template>
  <div>
    <div class="flex justify-between my-6">
      <div>
        <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
        <span>{{ formAnswer.form_content.title }}</span>
      </div>
    </div>

    <template v-if="imageUrl">
      <div v-if="isImage" class="w-full flex">
        <button class="overflow-hidden rounded-lg border-red-400 border-4 p-px" @click.stop="openImage">
          <img
            :src="thumbnailUrl"
            :alt="$t('Uploaded Image:') + imageName"
            class="w-20 h-20 object-cover overflow-hidden rounded block cursor-pointer"
          />
        </button>
      </div>
      <div v-else>
        <div class="flex justify-between rounded-xl border p-4 border-gray-200 bg-gray-100">
          <div>{{ imageName }}</div>
          <icon
            name="trash"
            class="w-4 h-4 text-gray-400 fill-current cursor-pointer"
            @click="showConfirmFileDeleteModal"
          />
        </div>
      </div>
      <div v-if="error" class="form-error">{{ error }}</div>
    </template>
    <div v-else class="block overflow-hidden">
      <input ref="file" type="file" class="hidden" @change="changed" />
      <button class="inline-block btn-red" @click.self="$refs.file.click()">
        {{ $t('Add Media') }}
      </button>
    </div>
    <styled-modal v-if="isImage && imageUrl" :show="showImageModal" class="rounded text-base" @close="closeImage">
      <template #title>{{ imageName }}</template>
      <template #content>
        <img
          v-if="imageUrl"
          :src="imageUrl"
          alt="Media"
          class="m-1 mx-auto block object-cover max-w-lg block max-h-[80vh] max-h-[80vh]"
        />
      </template>
      <template #footer>
        <div class="flex justify-between">
          <button type="button" class="text-gray-500" @click="closeImage">
            {{ $t('Close') }}
          </button>
          <front-solid-button class="ml-4" @click.self="onConfimedFileDelete">{{ $t('Delete') }}</front-solid-button>
        </div>
      </template>
    </styled-modal>
    <front-confirm-modal
      v-if="showConfirmFileDelete"
      :show="showConfirmFileDelete"
      @confirm="onConfimedFileDelete"
      @cancel="hideConfirmFileDeleteModal"
    >
      <template #title>{{ $t('Confirm Delete') }}</template>
      <template #content>
        <div class="flex flex-col space-y-4">
          <div>
            {{ $t("You're about to remove file") }}
            <b>{{ modelValue.file_name }}</b>
            .
          </div>
          <div>{{ $t('Are you sure you want to do that?') }}</div>
        </div>
      </template>
    </front-confirm-modal>
  </div>
</template>
<script>
import FrontFileInput from '../FrontFileInput.vue'
import Icon from '../Icon.vue'
export default {
  components: { FrontFileInput, Icon },
  props: {
    modelValue: Object,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      showImageModal: false,
      showConfirmFileDelete: false,
      imageUrl: this.modelValue?.url,
      thumbnailUrl: this.modelValue?.thumbnail_url,
      imageName: this.modelValue?.file_name,
      isImage: this.modelValue?.isImage,
    }
  },
  watch: {
    modelValue(newValue) {
      if (!newValue) {
        this.imageUrl = null
        this.thumbnailUrl = null
        this.imageName = null
        this.isImage = null
      } else if (newValue.url) {
        this.imageUrl = newValue.url
        this.thumbnailUrl = newValue.thumbnail_url ? newValue.thumbnail_url : newValue.url
        this.imageName = newValue.file_name
        this.isImage = newValue.isImage
      }
    },
  },
  methods: {
    changed(event) {
      if (!event.target.files.length) return

      this.value = event.target.files[0]
      this.$emit('update:modelValue', this.value)
      if (!this.value.type) return

      this.imageUrl = null
      this.imageName = this.value.name
      this.isImage = false

      if (this.value.type.startsWith('image')) {
        let reader = new FileReader()

        this.imageUrl = null
        this.thumbnailUrl = null
        this.isImage = true
        let vm = this
        reader.onload = function (e) {
          vm.thumbnailUrl = e.target.result
          vm.imageUrl = e.target.result
        }

        reader.readAsDataURL(this.value)
      }
    },
    openImage() {
      this.showImageModal = true
    },
    closeImage() {
      this.showImageModal = false
    },
    onConfimedFileDelete() {
      this.value = null
      this.hideConfirmFileDeleteModal()
      this.closeImage()
      this.$emit('update:modelValue', this.value)
    },
    showConfirmFileDeleteModal() {
      this.showConfirmFileDelete = true
    },
    hideConfirmFileDeleteModal() {
      this.showConfirmFileDelete = false
    },
  },
}
</script>
