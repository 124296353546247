<template>
  <div class="p-2 rounded">
    <div>{{ label }}</div>
    <div class="py-2"><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
  },
}
</script>
