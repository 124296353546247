<template>
  <div v-if="$page.props.auth.can.access_admin_backoffice" class="hidden md:block">
    <menu-item :name="$t('Admin')" :route-link="route('dashboard')">
      <icon name="setting" class="mr-3 h-6 w-6" style="margin-left: -0.15rem" />
    </menu-item>
  </div>
</template>

<script>
export default {}
</script>
