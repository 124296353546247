<template>
  <inertia-link
    v-if="routeLink"
    v-bind="$attrs"
    :href="routeLink"
    class="group -ml-3 flex items-center whitespace-nowrap border-l-4 py-2 pl-10 pr-4 text-lg"
    :class="cssClass"
    :method="method"
  >
    <slot />
    <span :class="{ 'border-white text-ts-gray-text-bold': !isUrl(url) }">
      {{ $t(name) }}
    </span>
  </inertia-link>
  <div
    v-else
    class="group -ml-3 flex items-center whitespace-nowrap border-l-4 border-white px-8 py-2"
    :class="isUrl(url) ? 'text-ts-gray-text-bold' : 'text-gray-600'"
  >
    <slot />
    <span :class="isUrl(url) ? 'text-ts-gray-text-bold' : 'text-gray-600'">
      {{ $t(name) }}
    </span>
  </div>
</template>

<script>
import { isUrl, isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    name: String,
    routeLink: String,
    inheritAttrs: false,
    url: {
      type: [String, Array],
      default: '',
    },
    method: {
      type: String,
      default: 'get',
    },
  },
  computed: {
    isTenantWorksite,
    cssClass() {
      if (isUrl(this.url)) {
        return this.isTenantWorksite
          ? 'border-qualify-red-500 fill-qualify-red-500 text-qualify-red-500 font-semibold'
          : 'border-blue-600 fill-blue-600 text-blue-600 font-semibold'
      } else {
        return 'border-white text-ts-gray-text-bold'
      }
    },
  },
  methods: {
    isUrl,
  },
}
</script>
