<template>
  <div>
    <label class="form-label">{{ $t('Organization Role:') }}</label>
    <dropdown
      class="btn-gray-outline relative mb-2 block w-full rounded border-gray-200 px-2"
      :class="{ 'border-red-500': error }"
      placement="bottom-end"
    >
      <div class="flex cursor-pointer select-none items-center justify-between">
        <div class="text-lg font-normal leading-6 text-gray-800">
          {{ selectedOrganizationRole ? selectedOrganizationRole.label : $t('Unknown') }}
        </div>
        <icon class="-my-1 h-5 w-5 fill-gray-500" name="cheveron-down" />
      </div>

      <template v-slot:dropdown="{ toggle }">
        <div class="mt-2 divide-y rounded border bg-white text-sm shadow-lg">
          <button
            class="block max-w-sm p-4 text-left hover:bg-gray-200 hover:text-gray-700"
            v-for="role in organizationRoles"
            :key="role.value"
            @click="selectRole(role), toggle()"
          >
            <div class="mb-2 text-base font-normal text-gray-800">{{ $t(role.label) }}</div>
            <div class="text-xs leading-4 text-gray-700">
              {{ $t(role.description) }}
            </div>
          </button>
        </div>
      </template>
    </dropdown>
    <span v-if="error" class="form-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'OrganizationRoleDropdown',
  props: {
    modelValue: [String, null],
    error: [String, null],
  },
  computed: {
    organizationRoles() {
      if (
        this.$page.props.auth.user.is_admin ||
        this.$page.props.auth.user.is_support ||
        this.$page.props.auth.user.is_super
      ) {
        return this.$page.props.qualify.organizationRoles
      }

      const currentUserRole = this.$page.props.qualify.organizationRoles.find(
        (role) => role.value === this.$page.props.auth.user.organization_role
      )

      return this.$page.props.qualify.organizationRoles.filter((role) => role.level <= currentUserRole.level)
    },
    selectedOrganizationRole() {
      return this.$page.props.qualify.organizationRoles.find((role) => role.value === this.modelValue)
    },
    basicRoleValue() {
      return 'basic'
    },
  },
  methods: {
    selectRole(role) {
      if (
        this.$page.props.auth.user.is_admin ||
        this.$page.props.auth.user.is_support ||
        this.$page.props.auth.user.is_super
      ) {
        this.$emit('update:modelValue', role.value)
        return
      }

      // Regular users cannot match their permission level. They can only set those below them.
      const currentUserRole = this.$page.props.qualify.organizationRoles.find(
        (role) => role.value === this.$page.props.auth.user.organization_role
      )

      if (role.level > currentUserRole.level) {
        return
      }

      this.$emit('update:modelValue', role.value)
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (!this.modelValue) {
      this.$emit('update:modelValue', this.basicRoleValue)
    }
  },
}
</script>
