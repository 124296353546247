<template>
  <styled-modal :show="show" class="rounded text-base" max-width="md" @close="close">
    <template #title>
      <h3 class="mt-4 text-center text-xl font-semibold leading-snug">{{ $t('Move Pages') }}</h3>
    </template>
    <template #content>
      <p class="mb-5 text-sm text-gray-600">Drag and drop to reorder pages, and save</p>
      <draggable :list="pages" tag="div" item-key="uuid" @end="onDragEnd">
        <template #item="{ element }">
          <div class="border-1 border-gray my-4 flex flex-row items-center bg-white py-2 text-gray-600 shadow">
            <Bars3Icon
              class="handle z-20 mx-3 flex h-6 w-6 cursor-move items-center justify-center"
              aria-hidden="true"
            />
            <p class="text-sm">{{ element.title }}</p>
          </div>
        </template>
      </draggable>
    </template>
    <template #footer>
      <button @click="close">{{ $t('Close') }}</button>
    </template>
  </styled-modal>
</template>

<script>
import { Bars3Icon } from '@heroicons/vue/24/outline'
import draggable from 'vuedraggable'

export default {
  components: { draggable, Bars3Icon },
  props: {
    pages: Array,
    show: Boolean,
  },
  inject: ['saveChanges'],
  methods: {
    close(user = null) {
      this.$emit('close')
    },
    onDragEnd() {
      this.saveChanges()
    },
  },
}
</script>
