<template>
  <nav class="flex items-center gap-x-6 overflow-x-scroll border-b border-ts-gray-500 px-10 pt-2 lg:overflow-hidden">
    <tab-menu-item
      v-for="menuItem in menuList"
      :key="menuItem.name"
      :name="menuItem.name"
      :url="menuItem.url"
      :matchUrl="menuItem.matchUrl"
    />
  </nav>
</template>
<script>
export default {
  props: {
    menuList: Array,
  },
}
</script>
