<template>
  <div>
    <div class="mb-4">
      <inertia-link
        v-if="defaultRoute"
        class="group flex items-center py-3"
        :href="defaultRoute"
        @click="showContents = !showContents"
      >
        <icon
          :name="icon"
          class="mr-2 h-4 w-4"
          :class="
            isUrl(routeLinks.map((route) => prefix + route))
              ? 'fill-gray-100'
              : 'fill-gray-400 group-hover:fill-gray-100'
          "
        />
        <div
          :class="
            isUrl(routeLinks.map((route) => prefix + route)) ? 'text-white' : 'text-gray-300 group-hover:text-white'
          "
        >
          {{ name }}
        </div>
      </inertia-link>
      <button v-else class="group flex items-center py-3" @click="showContents = !showContents">
        <icon :name="icon" class="mr-2 h-4 w-4 fill-gray-400 group-hover:fill-gray-100" />
        <div class="text-gray-300 group-hover:text-white">{{ name }}</div>
      </button>
    </div>
    <transition
      enter-active-class="transition duration-200 ease-in-out"
      leave-active-class="transition duration-200 ease-in-out"
      enter-class="opacity-0 transform -translate-y-5"
      enter-to-class="opacity-100 transform translate-y-0"
      leave-class="opacity-100 transform translate-y-0"
      leave-to-class="opacity-0 transform -translate-y-5"
    >
      <div v-if="showContents || isUrl(routeLinks.map((routeLink) => prefix + routeLink))">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'

export default {
  props: {
    name: String,
    icon: String,
    defaultRoute: String,
    routeLinks: Array,
  },
  data() {
    return {
      showContents: false,
    }
  },
  computed: {
    prefix() {
      return 'admin/'
    },
  },
  methods: {
    isUrl,
  },
}
</script>
