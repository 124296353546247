<template>
  <div>
    <menu-item name="Log out" :route-link="route('front.logout')" url="account/logout*">
      <icon name="logout" class="mr-3 h-6 w-6" />
    </menu-item>
  </div>
</template>

<script>
export default {}
</script>
