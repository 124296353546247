<template>
  <dropdown
    :class="isTenantWorksite ? 'btn-red-gradient' : 'tenant-btn-1'"
    :placement="placement"
    :boundary="boundary"
    :opacity="opacity"
    @beforeToggle="beforeToggle"
  >
    <slot />
    <template v-slot:dropdown>
      <slot name="dropdown"></slot>
    </template>
  </dropdown>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    placement: {
      type: String,
      default: 'bottom-end',
    },
    boundary: {
      type: String,
      default: 'scrollParent',
    },
    beforeToggle: {
      type: Function,
      default: () => {},
    },
    opacity: {
      type: String,
      default: 'opacity-25',
    },
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
