<template>
  <inertia-link
    :href="route('front.notifications')"
    class="relative ml-2 mr-5 rounded-full p-2"
    :class="isTenantWorksite ? 'bg-ts-red-600 ' : 'bg-blue-800 '"
  >
    <icon name="notification" class="block h-6 w-6 fill-white" />
    <span
      class="absolute -right-0.5 -top-0.5 flex h-5 w-5 items-center justify-center rounded-full bg-white text-sm"
      :class="[notificationsCount > 0 ? 'flex' : 'hidden', isTenantWorksite ? 'text-ts-red-800' : 'text-blue-800']"
    >
      <span v-if="notificationsCount < 10">{{ notificationsCount }}</span>
      <span v-else>+</span>
    </span>
  </inertia-link>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  data() {
    return {
      notificationsCount: this.$page.props.auth.user.notifications_count,
    }
  },
  mounted() {
    Echo.private(`user.${this.$page.props.auth.user.id}`)
      .listen('.notification.created', (e) => {
        this.notificationsCount = e.count
      })
      .listen('.notification.read', (e) => {
        this.notificationsCount = e.count
      })
  },
  computed: {
    isTenantWorksite,
  },
}
</script>
