<template>
  <transition leave-active-class="duration-200">
    <div v-show="show" class="fixed inset-0 z-50 flex h-screen w-full items-center justify-center">
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="show" class="fixed inset-0 transform transition-all" @click="close">
          <div class="absolute inset-0 bg-black opacity-25"></div>
        </div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 hidden sm:scale-95"
      >
        <div
          v-show="show"
          class="transform rounded-b-lg rounded-t-lg bg-white shadow-xl transition-all sm:w-full"
          :class="maxWidthClass"
        >
          <div class="space-y-4 px-6 py-4">
            <div class="border-b pb-3 text-lg font-semibold">
              <slot name="title" />
            </div>

            <div>
              <slot name="content" />
            </div>
          </div>

          <div class="overflow-hidden bg-gray-100 px-6 py-4 text-right">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
    },
    maxWidth: {
      default: '2xl',
    },
    closeable: {
      default: true,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      if (this.closeable) {
        this.$emit('close')
      }
    },
    closeOnEscape(e) {
      if (e.key === 'Escape' && this.show) {
        this.close()
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = null
        }
      },
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closeOnEscape)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape)
    document.body.style.overflow = null
  },
  computed: {
    maxWidthClass() {
      return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
      }[this.maxWidth]
    },
  },
}
</script>
