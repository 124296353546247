<template>
  <div class="relative -mx-3 -mt-2">
    <label>
      <input
        :id="id"
        ref="checkbox"
        type="checkbox"
        v-bind="$attrs"
        :checked="isChecked"
        :value="value"
        @change="onChange"
      />
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: String,
    value: {},
    modelValue: { default: '' },
    trueValue: { default: true },
    falseValue: { default: false },
  },
  emits: ['update:modelValue'],
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        if (this.modelValue[0] instanceof Object) {
          return this.modelValue.some((val) => val.id == this.value.id)
        }
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    },
  },
  methods: {
    onChange(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          if (this.modelValue[0] instanceof Object) {
            newValue.splice(
              newValue.findIndex((val) => val.id === this.value.id),
              1
            )
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }
        }
        this.$emit('update:modelValue', newValue)
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue)
      }
    },
  },
}
</script>
<style scoped>
input[type='checkbox'] {
  visibility: hidden;
}

input[type='checkbox'] ~ span {
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

input[type='checkbox'] ~ span:after {
  border: 2px solid #ededed;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

input[type='checkbox']:checked + span {
  background-color: #3b82f6;
}

input[type='checkbox']:checked + span:after {
  opacity: 1;
}

input[type='checkbox']:disabled + span {
  opacity: 0.5;
}
</style>
