<template>
  <div class="my-6">
    <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
    <span>{{ formAnswer.form_content.title }}</span>
  </div>
  <front-textarea-input
    v-if="formAnswer.form_content.form_questionable.is_multiline"
    v-model="value"
    :required="formAnswer.form_content.is_required"
    :error="error"
  />
  <front-text-input v-else v-model="value" :required="formAnswer.form_content.is_required" :error="error" />
</template>
<script>
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import FrontTextareaInput from '../FrontTextareaInput.vue'

export default {
  components: {
    FrontTextInput,
    FrontTextareaInput,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
