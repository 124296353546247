<template>
  <div class="my-6">
    <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
    <span>{{ formAnswer.form_content.title }}</span>
  </div>
  <front-text-input v-model="value" :required="formAnswer.form_content.is_required" :error="error" />
</template>
<script>
import FrontTextInput from '../FrontTextInput.vue'

export default {
  components: {
    FrontTextInput,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value:
        this.modelValue ??
        this.formAnswer.form_content.form_questionable.format.replace('[number]', this.formAnswer.form_answerable_id),
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
