<template>
  <div class="my-6">
    <span v-if="formAnswer.form_content.is_required" class="text-red-700">*</span>
    <span>{{ formAnswer.form_content.title }}</span>
  </div>
  <p v-if="formAnswer.form_content.form_questionable.format_type === 'Temperature'" class="my-4 text-sm text-gray-600">
    {{
      $t('Temperature should be between :temp_min - :temp_max:temp_unit', {
        temp_min: formAnswer.form_content.form_questionable.temp_min,
        temp_max: formAnswer.form_content.form_questionable.temp_max,
        temp_unit: tempUnits[formAnswer.form_content.form_questionable.temp_unit],
      })
    }}
  </p>
  <front-text-input v-model="value" type="number" :required="formAnswer.form_content.is_required" :error="error" />
</template>
<script>
import { TempUnitEnum } from '@/Enums/QuestionableEnums.js'
import FrontTextInput from '../FrontTextInput.vue'

export default {
  components: {
    FrontTextInput,
  },
  props: {
    modelValue: String,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      tempUnits: TempUnitEnum,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
