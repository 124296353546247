<template>
  <div ref="input">
    <label class="flex cursor-pointer items-center">
      <div class="relative">
        <input
          :id="id"
          ref="input"
          v-bind="$attrs"
          type="checkbox"
          class="hidden"
          :class="isTenantWorksite ? 'worksite-tenant' : 'other-tenant'"
          :checked="modelValue"
          @change.self="$emit('update:modelValue', $event.target.checked)"
        />
        <div class="toggle-line h-6 w-10 rounded-full bg-ts-gray-300 shadow-inner"></div>
        <div class="toggle-dot absolute inset-y-0 left-0 h-5 w-5 rounded-full bg-white shadow"></div>
      </div>
    </label>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    modelValue: Boolean,
    error: String,
  },
  emits: ['update:modelValue'],
  computed: {
    isTenantWorksite,
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>

<style scoped>
.toggle-dot {
  margin-top: 1.5px;
}
.toggle-dot,
.toggle-line {
  transition: all 0.3s ease-in-out;
}
input.worksite-tenant:checked ~ .toggle-line {
  background-color: #dd163d;
}
input.other-tenant:checked ~ .toggle-line {
  background-color: #2b6cb0;
}
input:checked ~ .toggle-dot {
  transform: translateX(95%);
}
</style>
