<template>
  <div>
    <div class="h-32 w-full sm:h-64" style="max-height: 65vh">
      <vue-signature-pad
        v-if="show"
        ref="signaturePad"
        class="rounded-2xl border border-ts-gray-500 bg-white"
        :options="{ onEnd }"
      />
    </div>
    <div class="flex justify-between gap-2 px-1 pt-1 text-sm">
      <button class="font-semibold tracking-wider text-ts-blue-600" @click.prevent="clear">
        {{ $t('Clear Signature') }}
      </button>
      <span v-if="error" class="form-error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import trimCanvas from 'trim-canvas'
import Http from '@/Utils/Http'
import QrcodeVue from 'qrcode.vue'
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: { QrcodeVue, VueSignaturePad },
  props: {
    show: Boolean,
    error: String,
  },
  data() {
    return {
      timer: null,
      showSignaturePadUrl: false,
      signaturePadUrl: null,
      signaturePadShortUrl: null,
      signature: null,
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  watch: {
    showSignaturePadUrl: function (newValue, oldValue) {
      if (newValue) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.signature = null
      this.$emit('updated', this.signature)
    },
    getTrimmedCanvas() {
      let copy = document.createElement('canvas')
      const signaturePadCanvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      copy.width = signaturePadCanvas.width
      copy.height = signaturePadCanvas.height
      copy.getContext('2d').drawImage(signaturePadCanvas, 0, 0)
      return trimCanvas(copy)
    },
    onEnd() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.signature = trimmedCanvas.toDataURL('image/png')
      this.$emit('updated', this.signature)
    },
    enableSignaturePadQrCode() {
      this.showSignaturePadUrl = true
      this.getSignaturePadUrl()
    },
    hideSignaturePadQrCode() {
      this.showSignaturePadUrl = false
    },
    getSignaturePadUrl() {
      Http.get(this.route('front.signature-url')).then((response) => {
        this.signaturePadUrl = response.data.url
        this.signaturePadShortUrl = response.data.short_url
      })
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getSignaturePadUrl()
        }, 240000)
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
  },
}
</script>
``
