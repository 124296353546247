<template>
  <div v-if="$page.props.app.tenant.phone" class="mb-1">
    <a
      :href="`tel:${$page.props.app.tenant.phone.formatted_number}`"
      class="group -ml-3 flex items-center whitespace-nowrap border-l-4 border-white py-2 pl-10 pr-4 text-lg text-ts-gray-text-bold"
    >
      <icon name="phone" class="mr-3 h-5 w-5" color="currentColor" stroke-width="2" />
      <span class="border-white text-ts-gray-text-bold">
        {{ $page.props.app.tenant.phone.number }}
      </span>
    </a>
  </div>
</template>

<script></script>
