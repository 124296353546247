<template>
  <front-checkbox-input class="my-6" v-model="value" :required="item.options.is_required" :label="item.title" />
</template>
<script>
import FrontCheckboxInput from '../FrontCheckboxInput.vue'

export default {
  components: {
    FrontCheckboxInput,
  },
  props: {
    modelValue: String,
    item: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
