<template>
  <div class="font-heading font-semibold">
    <div v-if="showInstructorDashboard" class="mb-4 text-xs uppercase text-gray-400">{{ $t('Instructor') }}</div>
    <div v-if="$page.props.auth.user.is_instructor" class="mb-4">
      <inertia-link class="group flex items-center py-3" :href="route('dashboard.instructors')">
        <icon
          name="dashboard"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'instructor') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'instructor') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Dashboard') }}
        </div>
      </inertia-link>
    </div>
    <div v-if="$page.props.auth.user.is_instructor" class="mb-4">
      <inertia-link class="group flex items-center py-3" :href="route('instructor.classes')">
        <icon
          name="apple"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'instructor/classes*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'instructor/classes*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Classes') }}
        </div>
      </inertia-link>
    </div>
    <div v-if="$page.props.auth.user.is_instructor" class="mb-4">
      <inertia-link class="group flex items-center py-3" :href="route('instructor.availability')">
        <icon
          name="calendar"
          class="mr-2 h-4 w-4"
          :class="
            isUrl(prefix + 'instructor/availability*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'
          "
        />
        <div
          :class="isUrl(prefix + 'instructor/availability*') ? 'text-white' : 'text-gray-300 group-hover:text-white'"
        >
          {{ $t('Availability') }}
        </div>
      </inertia-link>
    </div>
    <div v-if="showInstructorDashboard" class="mb-4 mt-12 text-xs uppercase text-gray-400">{{ $t('Admin') }}</div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('dashboard')">
        <icon
          name="dashboard"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix) ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix) ? 'text-white' : 'text-gray-300 group-hover:text-white'">{{ $t('Dashboard') }}</div>
      </inertia-link>
    </div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('organizations')">
        <icon
          name="office"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'organizations*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'organizations*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Organizations') }}
        </div>
      </inertia-link>
    </div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('users')">
        <icon
          name="users"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'users*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'users*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Users') }}
        </div>
      </inertia-link>
    </div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('enrolments')">
        <icon
          name="collection"
          class="mr-2 h-4 w-4"
          :class="
            isUrl(prefix + 'enrolments*')
              ? 'fill-transparent text-gray-100'
              : 'fill-transparent text-gray-400 group-hover:text-gray-100'
          "
        />
        <div :class="isUrl(prefix + 'enrolments*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Enrolments') }}
        </div>
      </inertia-link>
    </div>
    <main-menu-dropdown-item
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      :name="$t('Courses')"
      icon="book"
      :route-links="[
        'courses*',
        'course-location',
        'course-categories*',
        'instructor-led-classrooms*',
        'checkin-forms*',
        'quizzes*',
        'elearnings*',
        'files*',
        'links*',
        'certificate-templates*',
      ]"
      :defaultRoute="route('courses')"
    >
      <div
        v-if="
          showCourseDropdown ||
          isUrl([
            prefix + 'courses*',
            prefix + 'course-location',
            prefix + 'instructor-led-classrooms*',
            prefix + 'checkin-forms*',
            prefix + 'quizzes*',
            prefix + 'elearnings*',
            prefix + 'files*',
            prefix + 'links*',
            prefix + 'certificate-templates*',
          ])
        "
      >
        <div class="mb-4" v-if="$page.props.app.tenant.has_scorm_cloud_enabled">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('elearnings')">
            <div :class="isUrl(prefix + 'elearnings*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('eLearning') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link
            class="group -mt-4 flex items-center py-3 pl-6 text-sm"
            :href="route('instructor-led-classrooms')"
          >
            <div
              :class="
                isUrl(prefix + 'instructor-led-classrooms*') ? 'text-white' : 'text-gray-300 group-hover:text-white'
              "
            >
              {{ $t('Classes') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('checkin-forms')">
            <div :class="isUrl(prefix + 'checkin-forms*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Check-In Form') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('files')">
            <div :class="isUrl(prefix + 'files*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Files') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('links')">
            <div :class="isUrl(prefix + 'links*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Links') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('quizzes')">
            <div :class="isUrl(prefix + 'quizzes*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Quizzes') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('certificate-templates')">
            <div
              :class="isUrl(prefix + 'certificate-templates*') ? 'text-white' : 'text-gray-300 group-hover:text-white'"
            >
              {{ $t('Certificates') }}
            </div>
          </inertia-link>
        </div>
      </div>
    </main-menu-dropdown-item>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('classes')">
        <icon
          name="calendar"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'classes*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'classes*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Classes') }}
        </div>
      </inertia-link>
    </div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('locations')">
        <icon
          name="location"
          class="mr-2 h-4 w-4"
          :class="
            isUrl(prefix + 'locations*', prefix + 'rooms') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'
          "
        />
        <div
          :class="
            isUrl(prefix + 'locations*', prefix + 'rooms') ? 'text-white' : 'text-gray-300 group-hover:text-white'
          "
        >
          {{ $t('Locations') }}
        </div>
      </inertia-link>
    </div>
    <main-menu-dropdown-item
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      :name="$t('eCommerce')"
      icon="document"
      :route-links="['transactions*', 'subscription-plans*', 'resellers*']"
      class="mb-4"
      :defaultRoute="route('transactions')"
    >
      <div
        v-if="
          showCourseDropdown || isUrl([prefix + 'transactions*', prefix + 'subscription-plans*', prefix + 'resellers*'])
        "
      >
        <div
          class="mb-4"
          v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
        >
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('transactions')">
            <div :class="isUrl(prefix + 'transactions*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Transactions') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link
            class="group -mr-6 -mt-4 flex items-center py-3 pl-6 text-sm"
            :href="route('subscription-plans')"
          >
            <div :class="isUrl(prefix, 'subscription-plans*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Subscription Plans') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4" v-if="$page.props.auth.user.is_tenant_owner || $page.props.auth.user.is_super">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('resellers')">
            <div :class="isUrl(prefix + 'resellers*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Resellers') }}
            </div>
          </inertia-link>
        </div>
      </div>
    </main-menu-dropdown-item>
    <main-menu-dropdown-item
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      :name="$t('Marketing')"
      icon="megaphone"
      :route-links="['triggers*', 'subscription-banners*']"
      class="mb-4"
      :defaultRoute="route('triggers')"
    >
      <div v-if="showCourseDropdown || isUrl([prefix + 'triggers*', prefix + 'subscription-banners*'])">
        <div class="mb-4" v-if="$page.props.auth.user.is_super">
          <inertia-link class="group -mt-4 flex items-center py-3 pl-6 text-sm" :href="route('triggers')">
            <div :class="isUrl(prefix + 'triggers*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
              {{ $t('Automation Triggers') }}
            </div>
          </inertia-link>
        </div>
        <div class="mb-4">
          <inertia-link
            class="group -mr-6 -mt-4 flex items-center py-3 pl-6 text-sm"
            :href="route('subscription-banners')"
          >
            <div
              :class="isUrl(prefix, 'subscription-banners*') ? 'text-white' : 'text-gray-300 group-hover:text-white'"
            >
              {{ $t('Subscription Banners') }}
            </div>
          </inertia-link>
        </div>
      </div>
    </main-menu-dropdown-item>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-2 leading-tight" :href="route('plastic-card-orders')">
        <div class="mr-2 w-4">
          <icon
            name="credit"
            class="h-4 w-4"
            :class="
              isUrl(prefix + 'plastic-card-orders*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'
            "
          />
        </div>
        <div :class="isUrl(prefix + 'plastic-card-orders*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Plastic Cards') }}
        </div>
      </inertia-link>
    </div>
    <div
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
      class="mb-4"
    >
      <inertia-link class="group flex items-center py-3" :href="route('reports')">
        <icon
          name="printer"
          class="mr-2 h-4 w-4"
          :class="isUrl(prefix + 'reports*') ? 'fill-gray-100' : 'fill-gray-400 group-hover:fill-gray-100'"
        />
        <div :class="isUrl(prefix + 'reports*') ? 'text-white' : 'text-gray-300 group-hover:text-white'">
          {{ $t('Reports') }}
        </div>
      </inertia-link>
    </div>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'
import MainMenuDropdownItem from './MainMenuDropdownItem.vue'

export default {
  components: { MainMenuDropdownItem },
  data() {
    return {
      showContents: false,
      showCourseDropdown: false,
    }
  },
  computed: {
    showInstructorDashboard() {
      return (
        this.$page.props.auth.user.is_instructor &&
        (this.$page.props.auth.user.is_admin ||
          this.$page.props.auth.user.is_support ||
          this.$page.props.auth.user.is_super)
      )
    },
    prefix() {
      return 'admin/'
    },
  },
  methods: {
    isUrl,
  },
}
</script>
