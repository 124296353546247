<template>
  <div
    v-if="localeOptions && localeOptionKeys.length > 1"
    class="no-print flex justify-around overflow-hidden rounded bg-white"
  >
    <template v-for="(label, value) in localeOptions" :key="value">
      <button
        class="p-2 text-sm font-semibold"
        :class="value == currentLocale ? btnActiveCssClass : btnCssClass"
        @click="onClick(value)"
        :disabled="currentLocale === value"
      >
        <span>{{ label }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import { isTenantWorksite } from '@/Utils/Helpers'

export default {
  props: {
    currentLocale: String,
    localeOptions: Object,
  },
  emits: ['change'],
  computed: {
    localeOptionKeys() {
      return Object.keys(this.localeOptions)
    },
    btnCssClass() {
      return (
        'bg-white text-gray-700 ' + (this.isTenantWorksite() ? 'hover:text-qualify-red-500' : 'hover:text-blue-600')
      )
    },
    btnActiveCssClass() {
      return this.isTenantWorksite() ? 'bg-qualify-red-500 text-white' : 'bg-blue-600  text-white'
    },
  },
  methods: {
    isTenantWorksite,
    onClick(locale) {
      this.$emit('change', locale)
    },
  },
}
</script>
