<template>
  <div>
    <div
      class="text-m border-1 border-gray relative z-20 flex flex-row divide-x divide-y bg-white text-gray-400 shadow"
    >
      <TrashIcon
        v-if="itemCount > 1"
        class="absolute -right-8 h-10 w-5 cursor-pointer text-gray-500"
        aria-hidden="true"
        @click="onRemoveQuestion"
      />
      <div class="basis-2/3">
        <div class="flex items-center">
          <Bars3Icon
            v-if="itemCount > 1"
            class="handle z-20 mx-3 flex h-6 w-6 flex-none cursor-move items-center justify-center text-gray-400"
            aria-hidden="true"
          />
          <div v-else class="mx-3 h-6 w-6" aria-hidden="true"></div>
          <div class="w-full">
            <front-text-input
              class="placeholder-shown:text-bold placeholder-shown:text-m m-0 mt-0 border-0 bg-white px-2 py-3 ring-0 ring-inset placeholder-shown:border-0 placeholder-shown:ring-0 focus:outline-none focus:ring-2 focus:ring-qualify-red-600"
              v-model="title"
              :placeholder="$t('Type question')"
              @focus="item.isOptionVisible = true"
              @blur="item.isOptionVisible = false"
            />
          </div>
        </div>
      </div>
      <div class="basis-1/3">
        <Listbox as="div" v-model="selected">
          <div class="relative">
            <ListboxButton
              class="relative w-full cursor-default rounded-md bg-white px-2 py-2.5 text-left text-gray-700 ring-inset focus:outline-none focus:ring-2 focus:ring-qualify-red-600 sm:px-4 sm:text-sm sm:leading-6"
            >
              <span class="flex items-center">
                <div class="mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-gray-200"></div>
                <span class="block truncate">{{ selected.name }}</span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  v-for="response in responses"
                  :key="response.id"
                  :value="response"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      active ? 'bg-qualify-red-600 text-white' : 'text-gray-700',
                      'relative cursor-default select-none px-2 py-2 pr-9 sm:px-4',
                    ]"
                  >
                    <div class="flex items-center">
                      <div
                        class="mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-gray-200"
                      ></div>
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                        {{ response.name }}
                      </span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-qualify-red-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
    </div>

    <div v-if="item.isOptionVisible" class="z-10 flex flex-row divide-x bg-gray-100 py-2.5 text-sm text-gray-400">
      <div class="flex items-center px-4">
        <input
          id="required"
          aria-describedby="required-description"
          name="required"
          type="checkbox"
          class="mr-4 h-4 w-4 rounded border-gray-600 text-gray-600 focus:ring-indigo-500"
        />
        <label for="required" class="text-gray-600">Required</label>
      </div>

      <div class="flex items-center px-4">
        <input
          id="multipleSelection"
          aria-describedby="multipleSelection-description"
          name="multipleSelection"
          type="checkbox"
          class="mr-4 h-4 w-4 rounded border-gray-600 text-gray-600 focus:ring-indigo-500"
        />
        <label for="multipleSelection" class="text-gray-600">Multiple selection</label>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Popover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Bars3Icon,
    CheckIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    Bars3Icon,
    TrashIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    item: Object,
    itemCount: Number,
  },
  emits: ['remove:question', 'change:questionTitle'],
  mounted() {
    this.selected = this.responses.filter((response) => response.type === this.item.type)[0]
  },
  data() {
    return {
      title: this.item.title,
      responses: [
        {
          id: 1,
          name: 'Text Answer',
          type: 'FormText',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 2,
          name: 'Number',
          type: 'FormNumber',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 3,
          name: 'Checkbox',
          type: 'FormCheckbox',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 4,
          name: 'Date & Time',
          type: 'FormDateTime',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 5,
          name: 'Media',
          type: 'FormMedia',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 6,
          name: 'Slider',
          type: 'FormSlider',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 7,
          name: 'Document Number',
          type: 'FormDocumentNumber',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 8,
          name: 'Location',
          type: 'FormLocation',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 9,
          name: 'Text',
          type: 'FormText',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
        {
          id: 10,
          name: 'Select',
          type: 'FormSelect',
          icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
        },
      ],
      selected: {
        id: 1,
        name: 'Text Answer',
        type: 'FormText',
        icon: '<CheckIcon class="h-4 w-4 text-green-800" aria-hidden="true" />',
      },
    }
  },
  watch: {
    title(newTitle) {
      const item = this.item
      this.$emit('change:questionTitle', { item, newTitle })
    },
  },
  methods: {
    onRemoveQuestion() {
      this.$emit('remove:question', this.item)
    },
  },
}
</script>
