<template>
  <styled-modal max-width="md" :show="show" @close="$emit('close')">
    <template #title>
      <div class="flex justify-between">
        <span>{{ $t('Signature') }}</span>
        <template v-if="showQrCode">
          <a v-if="!showSignaturePadUrl" class="link text-sm" @click="enableSignaturePadQrCode()">
            {{ $t('Sign on different device') }}
          </a>
          <a v-else class="link text-sm" @click="hideSignaturePadQrCode()">{{ $t('Sign on this device') }}</a>
        </template>
      </div>
    </template>
    <template #content>
      <template v-if="!showSignaturePadUrl">
        <div class="h-32 w-full sm:h-64" style="max-height: 65vh">
          <vue-signature-pad v-if="show" ref="signaturePad" class="h-full w-full border" />
        </div>
        <button class="pt-2 text-xs text-ts-blue-600" @click.prevent="clear">{{ $t('Clear Signature') }}</button>
      </template>
      <div v-else class="flex flex-col items-center justify-center">
        <template v-if="signaturePadUrl">
          <qrcode-vue :value="signaturePadUrl" :size="200" level="H" />
          <span class="mt-2 text-sm">{{ $t('Use the QR code to sign from a different device') }}</span>
        </template>
        <div v-else class="flex h-32 w-full items-center justify-center sm:h-64" style="max-height: 65vh">
          <span>{{ $t('Loading...') }}</span>
        </div>
      </div>
    </template>

    <template #footer>
      <template v-if="!showSignaturePadUrl">
        <div class="flex w-full items-center justify-between">
          <button @click.prevent="close">{{ $t('Cancel') }}</button>
          <front-solid-button class="ml-4" @click.prevent="submit">
            <span>{{ $t('Approve and Sign') }}</span>
          </front-solid-button>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full items-center justify-end">
          <front-solid-button class="ml-4" @click.prevent="close">{{ $t('Close') }}</front-solid-button>
        </div>
      </template>
    </template>
  </styled-modal>
</template>

<script>
import trimCanvas from 'trim-canvas'
import Http from '@/Utils/Http'
import QrcodeVue from 'qrcode.vue'
import { useForm } from '@inertiajs/vue3'
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: { QrcodeVue, VueSignaturePad },
  props: {
    show: Boolean,
    showQrCode: {
      type: Boolean,
      default: true,
    },
    certificates: Array,
    role: String,
  },
  data() {
    return {
      timer: null,
      showSignaturePadUrl: false,
      signaturePadUrl: null,
      form: useForm({
        image: null,
      }),
    }
  },
  beforeUnmount() {
    this.stopTimer()
  },
  computed: {
    signatureUrlRouteName: function () {
      if (this.role == 'manager') return 'front.manager.certificates.signature-url'
      return 'front.certificates.signature-url'
    },
  },
  watch: {
    showSignaturePadUrl: function (newValue, oldValue) {
      if (newValue) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    getTrimmedCanvas() {
      let copy = document.createElement('canvas')
      const signaturePadCanvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      copy.width = signaturePadCanvas.width
      copy.height = signaturePadCanvas.height
      copy.getContext('2d').drawImage(signaturePadCanvas, 0, 0)
      return trimCanvas(copy)
    },
    submit() {
      let trimmedCanvas = this.getTrimmedCanvas()
      this.form.image = trimmedCanvas.toDataURL('image/png')
      this.$emit('signed', this.form.image)
      this.close()
    },
    enableSignaturePadQrCode() {
      this.showSignaturePadUrl = true
      this.getSignaturePadUrl()
    },
    hideSignaturePadQrCode() {
      this.showSignaturePadUrl = false
    },
    getSignaturePadUrl() {
      Http.get(this.route(this.signatureUrlRouteName, { certificates: this.certificates })).then((response) => {
        this.signaturePadUrl = response.data.url
      })
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getSignaturePadUrl()
        }, 240000)
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
