<template>
  <div
    v-if="isShowSubscriptionBanner"
    class="relative grid gap-6 grid-cols-1 rounded-xl bg-gradient-to-r from-[#3E484F] to-[#000000] px-16 py-8 mb-10 text-white"
    :class="{
      'lg:grid-cols-3': banner.column_type === 'three',
      'grid-cols-2': banner.column_type === 'two',
    }"
  >
    <div class="grid grid-rows-1 gap-6">
      <div
        class="px-3 py-2 rounded-md text-md text-center font-semibold uppercase bg-gradient-to-r from-ts-orange-900 to-qualify-red-900 text-white w-fit"
      >
        {{ banner.tag }}
      </div>
      <div class="text-3xl font-semibold text-center w-full lg:text-left lg:w-2/3">{{ banner.title }}</div>
      <div class="text-md text-center w-full lg:text-left lg:w-2/3 leading-normal">{{ banner.subtitle }}</div>
    </div>
    <div v-if="banner.column_type === 'three'" class="grid gap-3 content-center hidden lg:block">
      <div v-for="feature in getFeatures">
        <span class="inline-flex items-center">
          <CheckIcon class="w-6 h-6 stroke-qualify-red-900 mr-2" aria-hidden="true" />
          {{ feature }}
        </span>
      </div>
    </div>
    <div class="flex items-center place-content-center lg:place-content-end">
      <div class="w-4/5 text-center">
        <div v-if="preview" class="rounded-full bg-qualify-red-600 font-semibold px-8 py-5 block">
          {{ banner.button_text }}
        </div>
        <inertia-link
          v-else
          class="rounded-full bg-qualify-red-600 font-semibold px-8 py-5 block"
          :href="route(banner.button_route)"
        >
          {{ banner.button_text }}
        </inertia-link>
        <div class="text-sm mt-2 text-white/80">{{ banner.button_description }}</div>
      </div>
    </div>
    <div v-if="preview" class="absolute top-4 right-4">
      <XMarkIcon class="w-8 h-8 stroke-white" aria-hidden="true" />
    </div>
    <div v-else class="absolute top-4 right-4 cursor-pointer" @click="isShowSubscriptionBanner = false">
      <XMarkIcon class="w-8 h-8 stroke-white" aria-hidden="true" />
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  components: { CheckIcon, XMarkIcon },
  props: {
    preview: Boolean,
    banner: Object,
  },
  data() {
    return {
      isShowSubscriptionBanner: true,
    }
  },
  computed: {
    getFeatures() {
      return this.banner.features?.split('|')
    },
  },
}
</script>
