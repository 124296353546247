<template>
  <div v-if="status === 'transfer-pending'" :class="`${cssClasses} bg-gray-900 bg-opacity-8 text-gray-600`">
    {{ $t('Transfer: Pending') }}
  </div>
  <div v-if="status === 'new'" :class="`${cssClasses} bg-ts-orange-900 bg-opacity-8 text-ts-orange-900`">
    {{ $t('New') }}
  </div>
  <div v-else-if="status === 'in progress'" :class="`${cssClasses} bg-ts-blue-500 bg-opacity-8 text-ts-blue-500`">
    {{ $t('In progress') }}
  </div>
  <div v-else-if="status === 'failed'" :class="`${cssClasses} bg-red-500 bg-opacity-8 text-ts-red-500`">
    {{ $t('Failed') }}
  </div>
  <div
    v-else-if="status === 'complete'"
    :class="`${cssClasses} bg-ts-gray-text-bold bg-opacity-8 text-ts-gray-text-bold`"
  >
    {{ $t('Complete') }}
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cssClasses: 'inline-block px-2 py-3 rounded-md text-sm text-center font-semibold',
    }
  },
}
</script>
