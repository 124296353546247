<template>
  <div class="my-6">
    <span v-if="item.options.is_required" class="text-red-700">*</span>
    <span>{{ item.title }}</span>
  </div>

  <Multiselect
    v-if="item.options.is_multiple"
    v-model="value"
    :disabled="!select_options"
    :name="$t('Options')"
    :multiple="true"
    :options="select_options"
    :placeholder="$t('Select...')"
    :searchable="false"
    select-label=""
    deselect-label=""
    :close-on-select="true"
    :clear-on-select="false"
    track-by="id"
  >
    <template #noResult>{{ $t('No options found.') }}</template>
    <template #slot="noOptions">{{ $t('No options found.') }}</template>
    <template #option="optionProps">{{ optionProps.option.text }}</template>
    <template #selection="selectionProps">
      <option-tag v-for="option in selectionProps.values" :modelValue="option" :key="option.id" />
    </template>
  </Multiselect>
  <Multiselect
    v-else
    v-model="value"
    :disabled="!select_options"
    :name="$t('Options')"
    :options="select_options"
    :placeholder="$t('Select...')"
    :searchable="false"
    :close-on-select="true"
    :clear-on-select="false"
    :allow-empty="false"
    deselect-label=""
    select-label=""
    track-by="id"
  >
    <template #noResult>{{ $t('No options found.') }}</template>
    <template #slot="noOptions">{{ $t('No options found.') }}</template>
  </Multiselect>
</template>
<script>
import OptionTag from '@/Shared/Answerables/OptionTag.vue'
import Multiselect from 'vue-multiselect'
import FrontTextInput from '../FrontTextInput.vue'

export default {
  components: {
    Multiselect,
    FrontTextInput,
    OptionTag,
  },
  props: {
    modelValue: String,
    item: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: JSON.parse(this.modelValue) ?? null,
      select_options: this.item.options.form_select_options,
    }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', JSON.stringify(this.value))
    },
  },
}
</script>
