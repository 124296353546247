import { Link, createInertiaApp } from '@inertiajs/vue3'
import { createApp, h } from 'vue'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { i18nVue } from 'laravel-vue-i18n'
import _ from 'lodash'
import { ZiggyVue } from 'ziggy-js'
import '../css/app.css'
import './bootstrap.js'

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  progress: {
    // The delay after which the progress bar will appear
    // during navigation, in milliseconds.
    delay: 250,
    // The color of the progress bar.
    color: '#29d',
    // Whether to include the default NProgress styles.
    includeCSS: true,
    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(i18nVue, {
        resolve: async (lang) => {
          console.log(lang)
          const langs = import.meta.glob('../lang/*.json')
          return await langs[`../lang/${lang}.json`]()
        },
      })
      .component('InertiaLink', Link)

    const componentFiles = import.meta.globEager('./Shared/**/*.vue')

    Object.entries(componentFiles).forEach(([path, m]) => {
      const componentName = _.upperFirst(
        _.camelCase(
          path
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )

      app.component(componentName, m.default)
    })

    if (import.meta.env.VITE_BUGSNAG_API_KEY) {
      Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
      })

      app.use(Bugsnag.getPlugin('vue'))
    }

    app.config.globalProperties.$filters = {
      translate(word) {
        return Lang.get(word)
      },
    }

    app.mount(el)
  },
})
