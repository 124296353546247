<template>
  <h1 v-if="previousUrl" class="space-x-1 font-heading text-xl font-bold">
    <inertia-link v-if="previousUrl" class="text-ts-blue-500" :href="previousUrl">
      {{ previousName }}
    </inertia-link>
    <span v-if="name" class="font-medium text-ts-blue-500">/</span>
    <inertia-link v-if="middleUrl" class="text-ts-blue-500" :href="middleUrl">
      {{ middleName }}
    </inertia-link>
    <span v-if="middleUrl" class="font-medium text-ts-blue-500">/</span>
    <span>{{ name }}</span>
  </h1>
  <h1 v-else class="font-heading text-3xl font-semibold">
    {{ name }}
  </h1>
</template>

<script>
export default {
  props: {
    middleUrl: String,
    middleName: String | Number,
    previousUrl: String,
    previousName: String | Number,
    name: String,
  },
}
</script>
