<template>
  <div>
    <answer-view :label="formAnswer.form_content.title">
      <template v-if="imageUrl">
        <div v-if="isImage" class="w-full flex">
          <div class="overflow-hidden rounded-lg border-red-400 border-4 p-px" @click.stop="openImage">
            <img
              :src="imageUrl"
              :alt="$t('Uploaded Image:') + imageName"
              class="w-20 h-20 object-cover overflow-hidden rounded block cursor-pointer"
            />
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between rounded-xl border p-4 border-gray-200 bg-gray-100">
            <div>{{ imageName }}</div>
          </div>
        </div>
      </template>
      <styled-modal v-if="isImage && imageUrl" :show="showImageModal" class="rounded text-base" @close="closeImage">
        <template #title>{{ imageName }}</template>
        <template #content>
          <img
            v-if="imageUrl"
            :src="imageUrl"
            alt="Media"
            class="m-1 mx-auto object-cover max-w-lg block max-h-[80vh]"
          />
        </template>
        <template #footer>
          <div class="flex justify-between">
            <button type="button" class="text-gray-500">
              {{ $t('Close') }}
            </button>
          </div>
        </template>
      </styled-modal>
    </answer-view>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Object,
    formAnswer: Object,
    error: Object | String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue ?? null,
      showImageModal: false,
      showConfirmFileDelete: false,
      imageUrl: this.modelValue?.url,
      imageName: this.modelValue?.file_name,
      isImage: this.modelValue?.isImage,
    }
  },
  watch: {
    modelValue(newValue) {
      if (!newValue) {
        this.imageUrl = null
        this.imageName = null
        this.isImage = null
      } else if (newValue.url) {
        this.imageUrl = newValue.url
        this.imageName = newValue.file_name
        this.isImage = newValue.isImage
      }
    },
  },
  methods: {
    changed(event) {
      if (!event.target.files.length) return

      this.value = event.target.files[0]
      this.$emit('update:modelValue', this.value)
      if (!this.value.type) return

      this.imageUrl = null
      this.imageName = this.value.name
      this.isImage = false

      if (this.value.type.startsWith('image')) {
        let reader = new FileReader()

        this.imageUrl = null
        this.isImage = true
        reader.onload = (e) => (this.imageUrl = e.target.result)

        reader.readAsDataURL(this.value)
      }
    },
    openImage() {
      this.showImageModal = true
    },
    closeImage() {
      this.showImageModal = false
    },
  },
}
</script>
